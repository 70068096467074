import React from 'react'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Address = ({ intl }) => {
	return (
		<>
			<h2 className="sectionTitle">{intl.formatMessage({ id: "contact.dtt-head" })}</h2>
			<p><b>{intl.formatMessage({ id: "contact.dtt-line0" })}</b></p>
			<p><FormattedMessage
						id="contact.dtt-line1"
						defaultMessage={intl.formatMessage({ id: "contact.dtt-line1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
			</p>
			<p><FormattedMessage
						id="contact.dtt-line2"
						defaultMessage={intl.formatMessage({ id: "contact.dtt-line2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
			</p>
			<p><FormattedMessage
						id="contact.dtt-line3"
						defaultMessage={intl.formatMessage({ id: "contact.dtt-line3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
			</p>
			<p><FormattedMessage
						id="contact.dtt-line4"
						defaultMessage={intl.formatMessage({ id: "contact.dtt-line4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
			</p>
			<p><a href="mailto:dragon.tree.travel@gmail.com">{intl.formatMessage({ id: "contact.dtt-email" })}</a></p>
			{/* <p>{intl.formatMessage({ id: "contact.license" })}</p> */}
			{/* <p><a href="tel:+351910033399">{intl.formatMessage({ id: "contact.dtt-tel" })}</a></p> */}
		</>
	)
}

export default injectIntl(Address);
