import React from 'react'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Emailus = ({ intl }) => {
	return (
		<>
			<h2 className="sectionTitle">{intl.formatMessage({ id: "contact.email-head" })}</h2>
			<p>{intl.formatMessage({ id: "contact.email-p1" })}</p>
			<ul>
				<li><FormattedMessage
						id="contact.email-li1"
						defaultMessage={intl.formatMessage({ id: "contact.email-li1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
				</li>
				<li><FormattedMessage
						id="contact.email-li2"
						defaultMessage={intl.formatMessage({ id: "contact.email-li2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
				</li>
				<li><FormattedMessage
						id="contact.email-li3"
						defaultMessage={intl.formatMessage({ id: "contact.email-li3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
				</li>
				<li><FormattedMessage
						id="contact.email-li4"
						defaultMessage={intl.formatMessage({ id: "contact.email-li4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
				</li>
				<li><FormattedMessage
						id="contact.email-li5"
						defaultMessage={intl.formatMessage({ id: "contact.email-li5" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
				</li>
			</ul>
		</>
	)
}

export default injectIntl(Emailus)
