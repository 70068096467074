import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Emailus from "../components/emailus";
import Address from "../components/address";

const ContactPage = ({ intl }) => {
	return (
		<Layout pageName="prices" siteTitle="contact.page_title" intro="">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg container overlap" style={{ width: "100%", alignContent: "space-between"}}>
					<div className="col col--2">
						<Emailus />
					</div>
					<div className="col col--2">
						<Address />
					</div>
				</div>
			</section>
			{/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3591.00388174518!2d-16.9051356174649!3d32.67872123653799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCaminho%20de%20Ferro%20193%20Sitio%20das%20Tilias%20%2C%20Monte%209050-208%20Funchal%20Madeira%20%E2%80%93%20Portugal!5e0!3m2!1sen!2spl!4v1572208390909!5m2!1sen!2spl" width="100%" height="450" frameborder="0" style={{border:0, marginTop: "3em"}} allowfullscreen=""></iframe> */}
		</Layout>
	)
}

export default injectIntl(ContactPage)
